import React from 'react';

function Home() {
  return (
    <div>
      <h1>Benvingut a la Gestió de Fabricació de Finestres</h1>
      <p>Selecciona una opció per a començar.</p>
    </div>
  );
}

export default Home;