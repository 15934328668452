import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createMaterial, getMaterial, updateMaterial } from '../api';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Paper,
  Grid,
  Snackbar,
  Alert
} from '@mui/material';
import PageHeader from './PageHeader';

function MaterialForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [material, setMaterial] = useState({
    nombre: '',
    detalle: '',
    descuento_altura_persiana: 0,
    descuento_ancho_persiana: 0
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    if (id) {
      getMaterial(id).then(setMaterial).catch(err => setError(err.message));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaterial(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
    try {
      if (id) {
        await updateMaterial(id, material);
        setSnackbar({ open: true, message: 'Material actualitzat amb èxit', severity: 'success' });
      } else {
        await createMaterial(material);
        setSnackbar({ open: true, message: 'Material creat amb èxit', severity: 'success' });
      }
      // Eliminamos la navegación inmediata
      // navigate('/materiales');
    } catch (error) {
      setError(error.message);
      setSnackbar({ open: true, message: 'Error en guardar el material', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
    // Navegamos después de cerrar el Snackbar
    navigate('/materiales');
  };

  return (
    <Box sx={{ mt: 4 }}>
      <PageHeader title={id ? 'Editar Material' : 'Crear Material'} />
      <Paper elevation={3} sx={{ p: 3 }}>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nom"
                name="nombre"
                value={material.nombre}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Detall"
                name="detalle"
                value={material.detalle}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Descompte Alçada Persiana"
                name="descuento_altura_persiana"
                type="number"
                value={material.descuento_altura_persiana}
                onChange={handleChange}
                inputProps={{ step: "0.01" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Descompte Amplada Persiana"
                name="descuento_ancho_persiana"
                type="number"
                value={material.descuento_ancho_persiana}
                onChange={handleChange}
                inputProps={{ step: "0.01" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardant...' : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={3000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MaterialForm;