import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getVentanas, deleteVentana } from '../api';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Box,
  Typography
} from '@mui/material';
import PageHeader from './PageHeader';

function VentanasList() {
  const [ventanas, setVentanas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchVentanas();
  }, []);

  const fetchVentanas = async () => {
    try {
      setLoading(true);
      const data = await getVentanas();
      setVentanas(data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta ventana?')) {
      try {
        await deleteVentana(id);
        fetchVentanas();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  if (loading) return <Typography>Cargando ventanas...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box>
      <PageHeader title="Ventanas" />
      <Button 
        component={Link} 
        to="/ventanas/nueva" 
        variant="contained" 
        color="primary" 
        sx={{ mb: 2 }}
      >
        Crear Nueva Ventana
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Número de Ventana</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Cristal</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ventanas.map((ventana) => (
              <TableRow key={ventana.id}>
                <TableCell>{ventana.id}</TableCell>
                <TableCell>{ventana.cliente}</TableCell>
                <TableCell>{ventana.numero_ventana}</TableCell>
                <TableCell>{ventana.material_id}</TableCell>
                <TableCell>{ventana.cristal_id}</TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/ventanas/editar/${ventana.id}`} 
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 1 }}
                  >
                    Editar
                  </Button>
                  <Button 
                    onClick={() => handleDelete(ventana.id)} 
                    variant="outlined" 
                    color="error"
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default VentanasList;