import React, { useState, useEffect } from 'react';
import { 
  Button, 
  Typography, 
  CircularProgress, 
  Snackbar, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Box,
  Alert
} from '@mui/material';
import { generarEtiquetas, getClientesDistintos } from '../api';

function GeneradorEtiquetas() {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [clientes, setClientes] = useState([]);
  const [clienteSeleccionado, setClienteSeleccionado] = useState('');

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const data = await getClientesDistintos();
        setClientes(data);
      } catch (error) {
        console.error('Error al obtener clientes:', error);
        setSnackbar({ open: true, message: 'Error al cargar los clientes', severity: 'error' });
      }
    };

    fetchClientes();
  }, []);

  const handleGenerarEtiquetas = async () => {
    if (!clienteSeleccionado) {
      setSnackbar({ open: true, message: 'Por favor, selecciona un cliente', severity: 'warning' });
      return;
    }

    setLoading(true);
    try {
      const response = await generarEtiquetas(clienteSeleccionado);
      
      // Crear un blob con la respuesta y abrir en una nueva ventana
      const blob = new Blob([response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);

      setSnackbar({ open: true, message: 'PDF generado con éxito', severity: 'success' });
    } catch (error) {
      console.error('Error detallado al generar etiquetas:', error);
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
      <Typography variant="h6" gutterBottom>Generador de Etiquetas</Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="cliente-select-label">Cliente</InputLabel>
        <Select
          labelId="cliente-select-label"
          value={clienteSeleccionado}
          label="Cliente"
          onChange={(e) => setClienteSeleccionado(e.target.value)}
        >
          {clientes.map((cliente, index) => (
            <MenuItem key={index} value={cliente.cliente}>
              {cliente.cliente}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button 
        variant="contained" 
        onClick={handleGenerarEtiquetas} 
        disabled={loading || !clienteSeleccionado}
        fullWidth
      >
        {loading ? <CircularProgress size={24} /> : 'Generar Etiquetas'}
      </Button>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default GeneradorEtiquetas;