import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getFiguras, deleteFigura } from '../api';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button,   
  Box,
  Typography,
  Pagination,
  Snackbar,
  Alert
} from '@mui/material';
import PageHeader from './PageHeader';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

function FigurasList() {
  const [figuras, setFiguras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchFiguras();
    // eslint-disable-next-line
  }, [page]);

  const fetchFiguras = async () => {
    try {
      setLoading(true);
      const data = await getFiguras(page, 8);
      setFiguras(data.figuras);
      setTotalPages(data.totalPages);
      setError(null);
    } catch (err) {
      setError(err.message);
      setSnackbar({ open: true, message: 'Error al cargar las figuras', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta figura?')) {
      try {
        await deleteFigura(id);
        setSnackbar({ open: true, message: 'Figura eliminada con éxito', severity: 'success' });
        fetchFiguras(); // Recargar la lista después de eliminar
      } catch (err) {
        setSnackbar({ open: true, message: 'Error al eliminar la figura', severity: 'error' });
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) return <Typography>Carregant figures...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box>
      <PageHeader title="Figures" />
      <Button 
        component={Link} 
        to="/figuras/nueva" 
        variant="contained" 
        color="primary" 
        sx={{ mb: 2 }}
      >
        Crear Nova Figura
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tipus de Finestra ID</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Fulla 1</TableCell>
              <TableCell>Fulla 2</TableCell>
              <TableCell style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                Imatge
              </TableCell>
              <TableCell>Accions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {figuras.map((figura) => (
              <TableRow key={`${figura.id}-${figura.tipo_ventana_id}`}>
                <TableCell>{figura.id}</TableCell>
                <TableCell>{figura.tipo_ventana_id}</TableCell>
                <TableCell>{figura.nombre}</TableCell>
                <TableCell>{figura.hoja1 ? 'Sí' : 'No'}</TableCell>
                <TableCell>{figura.hoja2 ? 'Sí' : 'No'}</TableCell>
                <TableCell>
                  {figura.imagen && (
                  <img 
                    src={`${API_BASE_URL}/figura-imagen/${figura.id}/${figura.tipo_ventana_id}`}
                    alt={figura.nombre}
                    style={{ display: 'block', margin: '0 auto', width: 'auto', height: '50px' }}
                  />
                  )}
                </TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/figuras/${figura.id}/${figura.tipo_ventana_id}`}
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 1 }}
                  >
                    Editar
                  </Button>
                  <Button 
                    variant="outlined" 
                    color="error"
                    onClick={() => handleDelete(figura.id)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange} 
          color="primary" 
        />
      </Box>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FigurasList;
