import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getTiposVentanas, deleteTipoVentana } from '../api';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Box,
  Typography,
  Pagination,
  Snackbar,
  Alert
} from '@mui/material';
import PageHeader from './PageHeader';

function TiposVentanasList() {
  const [tiposVentanas, setTiposVentanas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchTiposVentanas();
  // eslint-disable-next-line    
  }, [page]);

  const fetchTiposVentanas = async () => {
    try {
      setLoading(true);
      const data = await getTiposVentanas(page, 10);
      setTiposVentanas(data.tiposVentanas);
      setTotalPages(data.totalPages);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Esteu segur que voleu eliminar aquest tipus de finestra?')) {
      try {
        await deleteTipoVentana(id);
        fetchTiposVentanas();
        setSnackbar({ open: true, message: 'Tipus de finestra eliminat amb èxit', severity: 'success' });
      } catch (err) {
        setError(err.message);
        setSnackbar({ open: true, message: 'Error en eliminar el tipus de finestra', severity: 'error' });
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) return <Typography>Carregant tipus de finestres...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box>
      <PageHeader title="Tipus de Finestres" />
      <Button 
        component={Link} 
        to="/tipos-ventanas/nuevo" 
        variant="contained" 
        color="primary" 
        sx={{ mb: 2 }}
      >
        Crear Nou Tipus de Finestra
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Línies</TableCell>
              <TableCell>Poste</TableCell>
              <TableCell>Ferro</TableCell>
              <TableCell>Accions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiposVentanas.map((tipo) => (
              <TableRow key={tipo.id}>
                <TableCell>{tipo.id}</TableCell>
                <TableCell>{tipo.material}</TableCell>
                <TableCell>{tipo.lineas}</TableCell>
                <TableCell>{tipo.poste ? 'Sí' : 'No'}</TableCell>
                <TableCell>{tipo.hierro ? 'Sí' : 'No'}</TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/tipos-ventanas/editar/${tipo.id}`} 
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 1 }}
                  >
                    Editar
                  </Button>
                  <Button 
                    onClick={() => handleDelete(tipo.id)} 
                    variant="outlined" 
                    color="error"
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange} 
          color="primary" 
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default TiposVentanasList;