const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

console.log('API_BASE_URL:', API_BASE_URL);

// Funciones auxiliares
async function fetchWithErrorHandling(url, options = {}) {
  const response = await fetch(url, options);
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Ha ocurrido un error');
  }
  return response.json();
}

// Ventanas
export const getVentanas = () => fetchWithErrorHandling(`${API_BASE_URL}/ventanas`);
export const getVentana = (id) => fetchWithErrorHandling(`${API_BASE_URL}/ventanas/${id}`);
export const createVentana = (data) => fetchWithErrorHandling(`${API_BASE_URL}/ventanas`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const updateVentana = (id, data) => fetchWithErrorHandling(`${API_BASE_URL}/ventanas/${id}`, {
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const deleteVentana = (id) => fetchWithErrorHandling(`${API_BASE_URL}/ventanas/${id}`, {
  method: 'DELETE',
});

// Materiales
export const getAllMateriales = async () => fetchWithErrorHandling(`${API_BASE_URL}/materiales/all`);
export const getMateriales = (page = 1, limit = 10) => fetchWithErrorHandling(`${API_BASE_URL}/materiales?page=${page}&limit=${limit}`);
export const getMaterial = (id) => fetchWithErrorHandling(`${API_BASE_URL}/materiales/${id}`);
export const createMaterial = (data) => fetchWithErrorHandling(`${API_BASE_URL}/materiales`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const updateMaterial = (id, data) => fetchWithErrorHandling(`${API_BASE_URL}/materiales/${id}`, {
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const deleteMaterial = (id) => fetchWithErrorHandling(`${API_BASE_URL}/materiales/${id}`, {
  method: 'DELETE',
});

// Cristales
export const getAllCristales = () => fetchWithErrorHandling(`${API_BASE_URL}/cristales/all`);
export const getCristales = (page = 1, limit = 10) => fetchWithErrorHandling(`${API_BASE_URL}/cristales?page=${page}&limit=${limit}`);
export const getCristal = (id) => fetchWithErrorHandling(`${API_BASE_URL}/cristales/${id}`);
export const createCristal = (data) => fetchWithErrorHandling(`${API_BASE_URL}/cristales`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const updateCristal = (id, data) => fetchWithErrorHandling(`${API_BASE_URL}/cristales/${id}`, {
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const deleteCristal = (id) => fetchWithErrorHandling(`${API_BASE_URL}/cristales/${id}`, {
  method: 'DELETE',
});

// Tipos de Ventanas

export const getAllTiposVentanas = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/materiales/all`);
    if (!response.ok) {
      throw new Error('Error al obtener materiales');
    }
    return response.json();
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
export const getTiposVentanas = (page = 1, limit = 10) => fetchWithErrorHandling(`${API_BASE_URL}/tipos-ventanas?page=${page}&limit=${limit}`);
export const getTipoVentana = (id) => fetchWithErrorHandling(`${API_BASE_URL}/tipos-ventanas/${id}`);
export const createTipoVentana = (data) => fetchWithErrorHandling(`${API_BASE_URL}/tipos-ventanas`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const updateTipoVentana = (id, data) => fetchWithErrorHandling(`${API_BASE_URL}/tipos-ventanas/${id}`, {
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const deleteTipoVentana = (id) => fetchWithErrorHandling(`${API_BASE_URL}/tipos-ventanas/${id}`, {
  method: 'DELETE',
});

// Figuras
export const getFiguras = (page = 1, limit = 8) => fetchWithErrorHandling(`${API_BASE_URL}/figuras?page=${page}&limit=${limit}`);
export const getFigura = (id, tipo_ventana_id) => fetchWithErrorHandling(`${API_BASE_URL}/figuras/${id}/${tipo_ventana_id}`);
export const createFigura = (data) => fetchWithErrorHandling(`${API_BASE_URL}/figuras`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data),
});
export const updateFigura = async (id, tipo_ventana_id, formData) => {
  const response = await fetch(`${API_BASE_URL}/figuras/${id}/${tipo_ventana_id}`, {
    method: 'PUT',
    body: formData
  });

  if (!response.ok) {
    throw new Error('Error al actualizar la figura');
  }

  return response.json();
};
export const deleteFigura = (id) => fetchWithErrorHandling(`${API_BASE_URL}/figuras/${id}`, {
  method: 'DELETE',
});

export const generarEtiquetas = async (cliente) => {
  try {
    const response = await fetch(`${API_BASE_URL}/generar-etiquetas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cliente }),
    });
    if (!response.ok) {
      throw new Error('Error al generar etiquetas');
    }
    return await response.blob();
  } catch (error) {
    console.error('Error en la función generarEtiquetas:', error);
    throw error;
  }
};

export const getClientesDistintos = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/clientes-distintos`);
    if (!response.ok) {
      throw new Error('Error al obtener la lista de clientes distintos');
    }
    return await response.json();
  } catch (error) {
    console.error('Error en la función getClientesDistintos:', error);
    throw error;
  }
};