import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFigura, createFigura, updateFigura } from '../api';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Paper,
  Grid,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import PageHeader from './PageHeader';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

function FiguraForm() {
  const { id, tipo_ventana_id } = useParams();
  const navigate = useNavigate();
  const [figura, setFigura] = useState({
    nombre: '',
    tipo_ventana_id: '',
    hoja1: false,
    hoja2: false,
    imagen: null
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    const fetchFigura = async () => {
      if (id && tipo_ventana_id) {
        try {
          const data = await getFigura(id, tipo_ventana_id);
          setFigura({
            ...data,
            hoja1: Boolean(data.hoja1),
            hoja2: Boolean(data.hoja2)
          });
          if (data.imagen) {
            setPreviewImage(`${API_BASE_URL}/figura-imagen/${id}/${tipo_ventana_id}`);
          }
        } catch (err) {
          setError(err.message);
          setSnackbar({ open: true, message: 'Error al cargar la figura', severity: 'error' });
        }
      }
    };

    fetchFigura();
  }, [id, tipo_ventana_id]);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === 'file') {
      const file = files[0];
      if (file) {
        if (file.size > 50 * 1024) {
          setError('El archivo es demasiado grande. El tamaño máximo es 50KB.');
          setSnackbar({ open: true, message: 'El archivo es demasiado grande', severity: 'error' });
          return;
        }
        if (file.type !== 'image/png') {
          setError('Solo se permiten archivos PNG.');
          setSnackbar({ open: true, message: 'Solo se permiten archivos PNG', severity: 'error' });
          return;
        }
        setFigura(prev => ({ ...prev, [name]: file }));
        setPreviewImage(URL.createObjectURL(file));
      }
    } else if (type === 'checkbox') {
      setFigura(prev => ({ ...prev, [name]: checked }));
    } else {
      setFigura(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      for (const key in figura) {
        if (key === 'imagen') {
          if (figura[key] instanceof File) {
            formData.append(key, figura[key]);
          }
        } else if (typeof figura[key] === 'boolean') {
          formData.append(key, figura[key] ? 'true' : 'false');
        } else {
          formData.append(key, figura[key]);
        }
      }

      if (id && tipo_ventana_id) {
        await updateFigura(id, tipo_ventana_id, formData);
        setSnackbar({ open: true, message: 'Figura actualitzada amb èxit', severity: 'success' });
      } else {
        await createFigura(formData);
        setSnackbar({ open: true, message: 'Figura creada amb èxit', severity: 'success' });
      }
      setTimeout(() => navigate('/figuras'), 2000); // Espera 2 segundos antes de navegar
    } catch (error) {
      setError(error.message);
      setSnackbar({ open: true, message: 'Error en guardar la figura', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <Box sx={{ mt: 4 }}>
      <PageHeader title={id && tipo_ventana_id ? 'Editar Figura' : 'Crear Figura'} />
      <Paper elevation={3} sx={{ p: 3 }}>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nom"
                name="nombre"
                value={figura.nombre}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Tipus de Finestra ID"
                name="tipo_ventana_id"
                value={figura.tipo_ventana_id}
                onChange={handleChange}
                required
                disabled={Boolean(id && tipo_ventana_id)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={figura.hoja1}
                    onChange={handleChange}
                    name="hoja1"
                  />
                }
                label="Fulla 1"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={figura.hoja2}
                    onChange={handleChange}
                    name="hoja2"
                  />
                }
                label="Fulla 2"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/png"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                name="imagen"
                onChange={handleChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Cargar Imagen (PNG, max 50KB)
                </Button>
              </label>
            </Grid>
            {previewImage && (
              <Grid item xs={12}>
                <img src={previewImage} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardant...' : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FiguraForm;