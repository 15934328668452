import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getMateriales, deleteMaterial } from '../api';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Box,
  Typography,
  Pagination,
  Snackbar,
  Alert
} from '@mui/material';
import PageHeader from './PageHeader';

function MaterialesList() {
  const [materiales, setMateriales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchMateriales();
    // eslint-disable-next-line
  }, [page]);

  const fetchMateriales = async () => {
    try {
      setLoading(true);
      const data = await getMateriales(page, 10);
      setMateriales(data.materiales);
      setTotalPages(data.totalPages);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Esteu segur que voleu eliminar aquest material?')) {
      try {
        await deleteMaterial(id);
        fetchMateriales();
        setSnackbar({ open: true, message: 'Material eliminat amb èxit', severity: 'success' });
      } catch (err) {
        setError(err.message);
        setSnackbar({ open: true, message: 'Error en eliminar el material', severity: 'error' });
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) return <Typography>Carregant materials...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box>
      <PageHeader title="Materials" />
      <Button 
        component={Link} 
        to="/materiales/nuevo" 
        variant="contained" 
        color="primary" 
        sx={{ mb: 2 }}
      >
        Crear Nou Material
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Detall</TableCell>
              <TableCell>Descompte Alçada Persiana</TableCell>
              <TableCell>Descompte Amplada Persiana</TableCell>
              <TableCell>Accions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materiales.map((material) => (
              <TableRow key={material.id}>
                <TableCell>{material.id}</TableCell>
                <TableCell>{material.nombre}</TableCell>
                <TableCell>{material.detalle}</TableCell>
                <TableCell>{material.descuento_altura_persiana}</TableCell>
                <TableCell>{material.descuento_ancho_persiana}</TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/materiales/editar/${material.id}`} 
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 1 }}
                  >
                    Editar
                  </Button>
                  <Button 
                    onClick={() => handleDelete(material.id)} 
                    variant="outlined" 
                    color="error"
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange} 
          color="primary" 
        />
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MaterialesList;