import React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WindowIcon from '@mui/icons-material/Window';
import BuildIcon from '@mui/icons-material/Build';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import CategoryIcon from '@mui/icons-material/Category';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import LabelIcon from '@mui/icons-material/Label';

const drawerWidth = 240;

function Sidebar() {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <List>
        <ListItem component={Link} to="/">
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem component={Link} to="/ventanas">
          <ListItemIcon><WindowIcon /></ListItemIcon>
          <ListItemText primary="Finestres" />
        </ListItem>
        <ListItem component={Link} to="/materiales">
          <ListItemIcon><BuildIcon /></ListItemIcon>
          <ListItemText primary="Materials" />
        </ListItem>
        <ListItem component={Link} to="/cristales">
          <ListItemIcon><BlurOnIcon /></ListItemIcon>
          <ListItemText primary="Vidres" />
        </ListItem>
        <ListItem component={Link} to="/tipos-ventanas">
          <ListItemIcon><CategoryIcon /></ListItemIcon>
          <ListItemText primary="Tipus de Finestres" />
        </ListItem>
        <ListItem component={Link} to="/figuras">
          <ListItemIcon><ShapeLineIcon /></ListItemIcon>
          <ListItemText primary="Figures" />
        </ListItem>
        <ListItem component={Link} to="/generar-etiquetas">
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary="Generar Etiquetas" />
        </ListItem>
      </List>
    </Drawer>
  );
}

export default Sidebar;