import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Home from './components/Home';
import VentanasList from './components/VentanasList';
import VentanaForm from './components/VentanaForm';
import MaterialesList from './components/MaterialesList';
import MaterialForm from './components/MaterialForm';
import CristalesList from './components/CristalesList';
import CristalForm from './components/CristalForm';
import TiposVentanasList from './components/TiposVentanasList';
import TipoVentanaForm from './components/TipoVentanaForm';
import FigurasList from './components/FigurasList';
import FiguraForm from './components/FiguraForm';
import GeneradorEtiquetas from './components/GeneradorEtiquetas';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Box sx={{ display: 'flex' }}>
          <Navbar />
          <Sidebar />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ventanas" element={<VentanasList />} />
              <Route path="/ventanas/nueva" element={<VentanaForm />} />
              <Route path="/ventanas/editar/:id" element={<VentanaForm />} />
              <Route path="/materiales" element={<MaterialesList />} />
              <Route path="/materiales/nuevo" element={<MaterialForm />} />
              <Route path="/materiales/editar/:id" element={<MaterialForm />} />
              <Route path="/cristales" element={<CristalesList />} />
              <Route path="/cristales/nuevo" element={<CristalForm />} />
              <Route path="/cristales/editar/:id" element={<CristalForm />} />
              <Route path="/tipos-ventanas" element={<TiposVentanasList />} />
              <Route path="/tipos-ventanas/nuevo" element={<TipoVentanaForm />} />
              <Route path="/tipos-ventanas/editar/:id" element={<TipoVentanaForm />} />
              <Route path="/figuras" element={<FigurasList />} />
              <Route path="/figuras/nueva" element={<FiguraForm />} />
              <Route path="/figuras/:id/:tipo_ventana_id" element={<FiguraForm />} />
              <Route path="/generar-etiquetas" element={<GeneradorEtiquetas />} />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;