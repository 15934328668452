import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createTipoVentana, getTipoVentana, updateTipoVentana } from '../api';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Paper,
  Grid,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import PageHeader from './PageHeader';

function TipoVentanaForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tipoVentana, setTipoVentana] = useState({
    material: '',
    lineas: 0,
    poste: false,
    hierro: false,
    medida_poste_batiente: 0,
    medida_poste_hierro: 0,
    altura_hierro_marco: 0,
    ancho_hierro_marco: 0,
    altura_hoja: 0,
    ancho_hoja: 0,
    altura_hierro_hoja: 0,
    ancho_hierro_hoja: 0,
    altura_cristal: 0,
    ancho_cristal: 0,
    altura_cristal_sin_hoja: 0,
    ancho_cristal_sin_hoja: 0
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    if (id) {
      getTipoVentana(id).then(setTipoVentana).catch(err => setError(err.message));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTipoVentana(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
    try {
      if (id) {
        await updateTipoVentana(id, tipoVentana);
        setSnackbar({ open: true, message: 'Tipus de finestra actualitzat amb èxit', severity: 'success' });
      } else {
        await createTipoVentana(tipoVentana);
        setSnackbar({ open: true, message: 'Tipus de finestra creat amb èxit', severity: 'success' });
      }
    } catch (error) {
      setError(error.message);
      setSnackbar({ open: true, message: 'Error en guardar el tipus de finestra', severity: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
    navigate('/tipos-ventanas');
  };

  return (
    <Box sx={{ mt: 4 }}>
      <PageHeader title={id ? 'Editar Tipus de Finestra' : 'Crear Tipus de Finestra'} />
      <Paper elevation={3} sx={{ p: 3 }}>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Material"
                name="material"
                value={tipoVentana.material}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Línies"
                name="lineas"
                type="number"
                value={tipoVentana.lineas}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tipoVentana.poste}
                    onChange={handleChange}
                    name="poste"
                  />
                }
                label="Poste"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tipoVentana.hierro}
                    onChange={handleChange}
                    name="hierro"
                  />
                }
                label="Ferro"
              />
            </Grid>
            {/* Añade aquí más campos según tu modelo de datos */}
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardant...' : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={3000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default TipoVentanaForm;