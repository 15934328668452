import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createVentana, getVentana, updateVentana, getAllCristales } from '../api';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import PageHeader from './PageHeader';

function VentanaForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ventana, setVentana] = useState({
    cliente: '',
    numero_ventana: '',
    material_id: '',
    cristal_id: '',
    // Añade aquí los demás campos según tu modelo de datos
  });
  const [cristales, setCristales] = useState([]);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchCristales = async () => {
      try {
        const data = await getAllCristales();
        setCristales(data);
      } catch (err) {
        setError('Error al cargar los cristales: ' + err.message);
      }
    };

    fetchCristales();

    if (id) {
      getVentana(id).then(setVentana).catch(err => setError(err.message));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVentana(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);
    try {
      if (id) {
        await updateVentana(id, ventana);
      } else {
        await createVentana(ventana);
      }
      navigate('/ventanas');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <PageHeader title={id ? 'Editar Ventana' : 'Crear Ventana'} />
      <Paper elevation={3} sx={{ p: 3 }}>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Cliente"
                name="cliente"
                value={ventana.cliente}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Número de Ventana"
                name="numero_ventana"
                type="number"
                value={ventana.numero_ventana}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Cristal</InputLabel>
                <Select
                  name="cristal_id"
                  value={ventana.cristal_id}
                  onChange={handleChange}
                  required
                >
                  {cristales.map((cristal) => (
                    <MenuItem key={cristal.id} value={cristal.id}>
                      {cristal.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Añade aquí los demás campos según tu modelo de datos */}
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Guardando...' : 'Guardar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}

export default VentanaForm;