import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getCristales, deleteCristal } from '../api';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  Box,
  Typography,
  Pagination
} from '@mui/material';
import PageHeader from './PageHeader';

function CristalesList() {
  const [cristales, setCristales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchCristales = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCristales(page, 10);
      setCristales(data.cristales);
      setTotalPages(data.totalPages);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchCristales();
  }, [fetchCristales]);

  const handleDelete = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este cristal?')) {
      try {
        await deleteCristal(id);
        fetchCristales();
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) return <Typography>Cargando cristales...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    <Box>
      <PageHeader title="Cristales" />
      <Button 
        component={Link} 
        to="/cristales/nuevo" 
        variant="contained" 
        color="primary" 
        sx={{ mb: 2 }}
      >
        Crear Nuevo Cristal
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cristales.map((cristal) => (
              <TableRow key={cristal.id}>
                <TableCell>{cristal.id}</TableCell>
                <TableCell>{cristal.nombre}</TableCell>
                <TableCell>{cristal.descripcion}</TableCell>
                <TableCell>
                  <Button 
                    component={Link} 
                    to={`/cristales/editar/${cristal.id}`} 
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 1 }}
                  >
                    Editar
                  </Button>
                  <Button 
                    onClick={() => handleDelete(cristal.id)} 
                    variant="outlined" 
                    color="error"
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange} 
          color="primary" 
        />
      </Box>
    </Box>
  );
}

export default CristalesList;